module.exports = {
	navigationLinks: [
		{
			name: 'Home',
			url: '/'
		},
		{
			name: 'About',
			url: '/about'
		},
		{
			name: 'Blog',
			url: '/blog'
		},
		{
			name: 'Games',
			url: '/games'
		}
	],

	indexBackgroundImageUrl : ""
};